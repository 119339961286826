////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(221, 221, 221, 1);
}
.bg-fourthColor {
  background-color: rgba(95, 191, 243, 1);
}
.bg-fifthColor {
  background-color: rgba(126, 197, 83, 1);
}
.bg-sixthColor {
  background-color: rgba(95, 191, 243, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
